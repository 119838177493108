<template>
    <div class="header_content_info_content bg-gray-700">
        <div class="header_content_info_content">
            <div class="header_content_info container-xl">
                <div class="header_content_info_row row">
                    <div class="col-xl-7 col-lg-12 d-flex justify-content-center p-0">
                        <BannerSlider :slides="banners" />
                    </div>
                    <div class="col-xl-5 col-lg-12 header_content_info_col header_content_info_forms">
                        <h2 class="header_content_info_forms_title">
                            {{ calculateForm?.titles[0].content }}
                        </h2>
                        <div class="search_by_code_form">
                            <default-input
                                v-model="searchFormCode"
                                :clear-button="true"
                                :title="true"
                                :class="{
                                    header_content_info_forms: true,
                                    'loading loading--small': searchFormCodeLoading,
                                    search_by_code_form_result_error_input: searchFormCodeResult.success === false,
                                }"
                                mask="#### #### ####"
                                :placeholder="calculateForm?.fields[0].content"
                                @keyup.enter="searchProductCode(searchFormCode)"
                                @input="searchFormCodeResult.success === false ? updateSearchFormCodeResult(translateData.value.cargo_search_placeholder.value) : null"
                            >
                                <template #container-right>
                                    <img class="default_input_search_button" src="@/assets/images/search_icon.svg" alt="" @click="searchProductCode(searchFormCode)" />
                                </template>
                            </default-input>

                            <div
                                v-if="searchFormCodeResult.success === false"
                                :class="{
                                    search_by_code_form_result: true,
                                    search_by_code_form_result_success: searchFormCodeResult.success === true,
                                    search_by_code_form_result_error_text: searchFormCodeResult.success === false,
                                    search_by_code_form_result_idle: searchFormCodeResult.success === null,
                                }"
                            >
                                {{ searchFormCodeResult.text }}
                            </div>
                        </div>
                        <div class="header_content_info_calculate_block w-100 d-block align-items-start flex-column">
                            <h2 class="header_content_info_forms_title">
                                {{ calculateForm?.titles[1].content }}
                            </h2>

                            <div class="header_content_info_calculate_forms_block w-100 justify-content-between">
                                <div
                                    v-on-click-outside="handleClickOutsideSuggestDropdownFrom"
                                    :class="{
                                        'front-direction-field-new_control-wrapper': true,
                                        'loading loading--small': transportationRateLoading,
                                    }"
                                >
                                    <default-input
                                        v-model="calculateSumForms.from"
                                        class="header_content_info_calculate_form_input_size"
                                        :placeholder="calculateForm?.fields[1].content"
                                        :title="true"
                                        @focus="calculateSumForms.fromHidden = false"
                                    />

                                    <div
                                        class="front-direction-field-new_dropdown"
                                        :style="{
                                            display: calculateSumForms.fromHidden || shipFromHints.length === 0 ? 'none' : undefined,
                                        }"
                                    >
                                        <div class="front-direction-field-new_dropdown-inner">
                                            <div class="scroll-container-root">
                                                <ul class="front-suggest-new_suggest-list">
                                                    <template v-for="(hint, hintIndex) in shipFromHints" :key="hintIndex">
                                                        <li class="front-suggest-new_suggest-list__item" @click="handleSelectCalculateFrom(hint.value)">
                                                            <div class="front-suggest-new_suggest-list__item-title">
                                                                {{ hint.text }}
                                                            </div>
                                                            <div class="front-suggest-new_suggest-list__item-text">
                                                                {{ hint.value }}
                                                            </div>
                                                        </li>
                                                    </template>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <img
                                    class="header_content_info_calculate_form_icons"
                                    :class="{ loading: isCalculation }"
                                    src="@/assets/images/arrow_double.svg"
                                    alt=""
                                    @:click="updateСalculation(calculateSumForms.to, calculateSumForms.from)"
                                />

                                <div
                                    v-on-click-outside="handleClickOutsideSuggestDropdownTo"
                                    :class="{
                                        'front-direction-field-new_control-wrapper': true,
                                        'loading loading--small': transportationRateLoading,
                                    }"
                                >
                                    <default-input
                                        v-model="calculateSumForms.to"
                                        class="header_content_info_calculate_form_input_size"
                                        :placeholder="calculateForm?.fields[2].content"
                                        :title="true"
                                        @focus="calculateSumForms.toHidden = false"
                                    />
                                    <div
                                        class="front-direction-field-new_dropdown"
                                        :style="{
                                            display: calculateSumForms.toHidden || shipToHints.length === 0 ? 'none' : undefined,
                                        }"
                                    >
                                        <div class="front-direction-field-new_dropdown-inner">
                                            <div class="scroll-container-root">
                                                <ul class="front-suggest-new_suggest-list">
                                                    <template v-for="(hint, hintIndex) in shipToHints" :key="hintIndex">
                                                        <li class="front-suggest-new_suggest-list__item" @click="handleSelectCalculateTo(hint.value)">
                                                            <div class="front-suggest-new_suggest-list__item-title">
                                                                {{ hint.text }}
                                                            </div>
                                                            <div class="front-suggest-new_suggest-list__item-text">
                                                                {{ hint.value }}
                                                            </div>
                                                        </li>
                                                    </template>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <form method="post" action="https://pecom.ru/services-are/shipping-request/">
                                <input type="hidden" name="addressFrom" :value="calculateSumForms.from" />
                                <input type="hidden" name="addressTo" :value="calculateSumForms.to" />

                                <div class="header_content_info_calculate_button_block">
                                    <default-button
                                        size="large"
                                        type="submit"
                                        :class="{
                                            header_content_info_calculate_button: true,
                                            'loading loading--small': transportationRateLoading,
                                        }"
                                    >
                                        {{ calculateForm?.fields[3]?.content }}
                                    </default-button>
                                </div>

                                <template v-if="computedMinTransportationRateInfo !== null">
                                    <div
                                        class="header_content_info_calculate_result w-100 d-flex justify-content-start justify-content-sm-center justify-content-md-center justify-content-xl-end justify-content-xxl-end"
                                    >
                                        <img class="header_content_info_calculate_result_icons d-none d-sm-block d-md-block d-xl-block" src="@/assets/images/arrow_crocked.svg" alt="" />

                                        <span class="header_content_info_calculate_result_text">
                                            {{ getTransitCostBlockMessage(computedMinTransportationRateInfo) }}
                                        </span>
                                    </div>
                                </template>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="header_content_advantages_block">
                    <div v-for="(itemList, itemIndex) in calculateForm?.list" :key="itemIndex" class="header_content_advantages d-flex align-items-start justify-content-start">
                        <div class="header_content_advantages_info_block">
                            <div class="header_content_advantages_number_block">
                                <div class="header_content_advantages_number">
                                    <div class="d-flex gap-1 h-100 content-text-advantages">
                                        <div class="d-flex gap-1 h-100 content-text-advantages-spans">
                                            <span>{{ itemList.number.split(' ')[0] }}</span>
                                            <span class="header_content_advantages_number_postfix">
                                                {{ itemList.number.split(' ')[1] }}
                                            </span>
                                        </div>
                                        <div class="icon-plus-container d-flex"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="header_content_advantages_text_block">
                                <p class="header_content_advantages_text">
                                    {{ itemList.content }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import defaultInput from '~~/components/Input/DefaultInput.vue';
import defaultButton from '~~/components/Button/DefaultButton.vue';
import { watchDebounced } from '@vueuse/core';
import { vOnClickOutside } from '@vueuse/components';
import { onMounted } from 'vue';
import { ocrGetAsync } from '~~/api/contentInfo/ocr/ocr';
import { getSugggest } from '@/pages/index/utils/getSugggest';
import { getNeedCalculate } from '@/pages/index/utils/getNeedCalculate';
import BannerSlider from '~/pages/index/components/BannerSlider.vue';
import { useTranslateLayOut } from '~/stores/translateStore.ts';
import { useLang } from '~/stores/langStore';
const props = defineProps({
    helpers: {
        type: Object,
    },
    calculateForm: {
        type: Object,
    },
    banners: {
        type: Object,
    },
});

const langStore = useLang();
const translateStore = useTranslateLayOut();
const translateData = computed(() => translateStore.translateData);

const searchFormCodeResult = reactive({
    success: '',
    text: translateData.value.cargo_search_placeholder?.value || 'Введите код чтобы найти груз',
});

const searchFormCode = ref('');
const searchFormCodeLoading = ref(false);
const shipFromHints = ref([]);
const shipToHints = ref([]);
const transportationRate = ref(null);
const transportationRateLoading = ref(false);
const isCalculation = ref(false);

const calculateSumForms = reactive({
    from: props.helpers?.city_from.value || 'Астана',
    fromHidden: true,
    to: props.helpers?.city_to?.value || 'Москва',
    toHidden: true,
});

const searchProductCode = async code => {
    searchFormCodeLoading.value = true;
    const result = await isProductCode(code);
    searchFormCodeLoading.value = false;

    if (result.status === 'error') {
        searchFormCodeResult.text = translateData.value.cargo_not_found_text.value;
        searchFormCodeResult.success = false;
    } else {
        searchFormCodeResult.success = true;
        window.location.href = `https://pecom.ru/services-are/order-status/?code=${code}`;
    }
};

const isProductCode = async code => {
    const codeRaw = code.replaceAll(' ', '');

    if (codeRaw === '') {
        return;
    }

    const result = await ocrGetAsync(codeRaw);
    const failed = result.error?.code === -33000;

    if (failed) {
        return {
            status: 'error',
        };
    } else {
        return {
            status: 'success',
        };
    }
};

const swapPlaceValue = (to, from) => {
    let result = {};
    const replace = to;
    to = from;
    from = replace;
    result = {
        to: to,
        from: from,
    };
    return result;
};

const swapPlaceCites = async (to, from) => {
    const result = swapPlaceValue(to, from);
    calculateSumForms.to = result.to;
    calculateSumForms.from = result.from;
};

const handleClickOutsideSuggestDropdownFrom = e => {
    const isWrapper = e.target.matches('.front-direction-field-new_control-wrapper, .front-direction-field-new_control-wrapper *');

    if (isWrapper === false) {
        calculateSumForms.fromHidden = true;
    }
};

const handleClickOutsideSuggestDropdownTo = e => {
    const isWrapper = e.target.matches('.front-direction-field-new_control-wrapper, .front-direction-field-new_control-wrapper *');

    if (isWrapper === false) {
        calculateSumForms.toHidden = true;
    }
};

const getTransitCostBlockMessage = transportInfo => {
    let dayText = transportInfo.daysCount.max > 1 ? translateData.value.few_days.value : translateData.value.one_day.value;
    const fromText = translateData.value.before_transit_price?.value || 'От';
    const approximateTime = translateData.value.approximate_time.value || 'примерный срок';

    if (langStore.langCode === 'kk') {
        return `${transportInfo.price.value} теңгеден ${fromText}, ${approximateTime} ${transportInfo.daysCount.min}-${transportInfo.daysCount.max} ${dayText}`;
    }

    if (langStore.langCode === 'ru' && transportInfo.daysCount.max % 10 >= 2 && transportInfo.daysCount.max % 10 <= 4) {
        dayText = 'дня';
    }

    return `${fromText} ${transportInfo.price.value} ${langStore.langCode === 'ru' ? 'тенге' : 'tenge'}, ${approximateTime} ${transportInfo.daysCount.min}-${transportInfo.daysCount.max} ${dayText}`;
};

const computedMinTransportationRateInfo = computed(() => {
    if (transportationRate?.value === null) {
        return null;
    }

    return (
        Object.values(transportationRate.value)
            .filter(item => item !== null)
            .sort((a, b) => (a.price.value > b.price.value ? 1 : a.price.value < b.price.value ? -1 : 0))
            .at(0) || null
    );
});

const handleSelectCalculateFrom = v => {
    calculateSumForms.from = v;
    calculateSumForms.fromHidden = true;
    checkNeedCalculate();
};

const handleSelectCalculateTo = v => {
    calculateSumForms.to = v;
    calculateSumForms.toHidden = true;
    checkNeedCalculate();
};

const checkNeedCalculate = async () => {
    if (calculateSumForms.from.trim() === '' || calculateSumForms.to.trim() === '') {
        return;
    }
    transportationRateLoading.value = true;
    transportationRate.value = await getNeedCalculate(calculateSumForms.to, calculateSumForms.from);
    transportationRateLoading.value = false;
};

const updateSearchFormCodeResult = value => {
    searchFormCodeResult.success = '';
    searchFormCodeResult.text = value;
};

const updateStatusCalculation = status => {
    isCalculation.value = status;
};

const updateСalculation = async (to, from) => {
    updateStatusCalculation(true);
    swapPlaceCites(to, from);
    await checkNeedCalculate();
    updateStatusCalculation(false);
};

watchDebounced(
    () => calculateSumForms.from,
    async value => {
        shipFromHints.value = await getSugggest(value);
    },
    {
        debounce: 200,
    }
);

watchDebounced(
    () => calculateSumForms.to,
    async value => {
        shipToHints.value = await getSugggest(value);
    },
    {
        debounce: 200,
    }
);

onMounted(() => {
    setTimeout(() => {
        checkNeedCalculate();
    }, 1000);
});
</script>

<style>
.search_by_code_form_result {
    font-size: 12px;
}

.search_by_code_form_result_success {
    color: #60de49;
}

.search_by_code_form_result_error_text {
    color: #ff465c;
}

.search_by_code_form_result_error_input {
    border: 1px solid #ff465c !important;
}

.search_by_code_form_result_idle {
    color: #cbcbcb;
}

.default_hover_icons:hover {
    filter: invert(59%) sepia(57%) saturate(2936%) hue-rotate(211deg) brightness(104%) contrast(106%);
}

.default_input_search_button:hover {
    cursor: pointer;
    filter: invert(59%) sepia(57%) saturate(2936%) hue-rotate(211deg) brightness(104%) contrast(106%);
}

.header_content_info_calculate_form_icons:hover {
    cursor: pointer;
    filter: invert(59%) sepia(57%) saturate(2936%) hue-rotate(211deg) brightness(104%) contrast(106%);
}

.header_content_info_forms_title {
    line-height: 32px;
}

.front-direction-field-new_control-wrapper {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    background-color: #fff;
}

.front-direction-field-new_control-wrapper .front-direction-field-new_dropdown {
    position: absolute;
    z-index: 0;
    width: 100%;
    left: 0;
    top: 100%;
    padding: 0.5rem 0 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e6e7;
    -webkit-box-shadow: 0 4px 16px hsla(0, 0%, 82%, 0.25);
    box-shadow: 0 4px 16px hsla(0, 0%, 82%, 0.25);
    border-radius: 3px;
}

.front-direction-field-new_dropdown {
    z-index: 1000 !important;
}

.front-direction-field-new_control-wrapper .front-direction-field-new_dropdown-inner {
    margin-right: 8px;
    max-height: 396px;
}

.front-suggest-new_suggest-list {
    z-index: 100;
    padding: 0;
    margin: 0;
}

.front-suggest-new_suggest-list__item {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 14px;
    text-align: left;
    border: none;
    outline: 0;
    background: #fff;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.front-suggest-new_suggest-list__item-title {
    color: #000;
}

.front-suggest-new_suggest-list__item-title span {
    color: #e4003c;
}

.front-suggest-new_suggest-list__item-text {
    color: #9a9a9a;
}

.front-suggest-new_suggest-list__item:hover .front-suggest-new_suggest-list__item-text,
.front-suggest-new_suggest-list__item:hover .front-suggest-new_suggest-list__item-title,
.front-suggest-new_suggest-list__item:hover .front-suggest-new_suggest-list__item-title span {
    color: #968eff;
}

.default_header_block {
    border-bottom: 1px solid #e4e6e7;
}

.row_block {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.col_block {
    flex-grow: 20;
}

.header_content_info_calculate_forms_block {
    display: flex;
}

.header_content_info_content {
    margin-top: Calc(50px + 73px);
}

.header_content_advantages_block {
    margin-top: 35px;
    margin-bottom: 120px;
}

.header_content_advantages_info_block {
    display: flex;
    border-top: 1px solid #e4e6e7;
}

.header_content_advantages_linear {
    width: 50%;
    height: 1px;
}

.header_content_advantages_number_block {
    font-size: 32px;
    margin-right: 24px;
    color: #e4003c;
}

.default_linear_title_block {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.header_content_info_calculate_button {
    font-weight: 700;
    color: #ffffff;
}

.header_content_advantages_number {
    font-size: 48px;
    font-weight: 400;
    display: flex;
}

.header_content_info_calculate_button:hover .header_content_advantages_number_icons {
    background: #000 !important;
}

.header_content_advantages_number_postfix {
    font-size: 32px;
    height: auto;
}
.content-text-advantages {
    align-items: flex-start;
    vertical-align: text-top;
    display: flex;
}
.content-text-advantages-spans {
    align-items: baseline;
}
.icon-plus-container:after {
    content: '+';
    font-size: 32px;
}

.default_disabled {
}

.header_content_advantages_text {
    font-size: 18px;
    line-height: 26px;
    height: 100%;
    margin: 0;
    display: flex;
    text-align: left;
    align-items: center;
}

.header_content_info_calculate_form_icons {
    cursor: pointer;
}

@media (max-width: 1159px) {
    .header_content_advantages_info_block {
        display: block;
    }

    .header_content_advantages_number_block {
        margin: 0 0 24px;
    }
}

@media (max-width: 1000px) {
    .header_content_info_calculate_form_input_size {
        width: 100%;
    }

    .header_content_info_calculate_form_icons {
        margin: 15px;
        cursor: pointer;
    }
}

@media (max-width: 1120px) {
    .header_logo_block {
        width: 30%;
    }

    .header_menu_block {
        width: 70%;
    }

    .header_content_info_calculate_form_input_size {
        min-width: 210px;
    }
}

@media (max-width: 912px) {
    .header_content_advantages_info_block {
        display: block;
    }

    .header_content_info_calculate_block {
        margin-top: 50px;
    }
}

@media (max-width: 780px) {
    .header_content_advantages_info_block {
        width: 100%;
    }

    .header_content_advantages_text {
        max-width: 320px;
    }
}

@media (max-width: 575px) {
    .header_content_info_calculate_button_block {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header_content_info_calculate_button {
        width: 100%;
    }

    .header_content_info_calculate_result {
        display: flex;
        justify-content: center !important;
    }

    .header_content_info_calculate_result {
        margin-top: 24px;
    }
}

@media (max-width: 510px) {
    .header_content_info_calculate_result {
        justify-content: center;
        margin: 20px 0px;
    }

    .header_content_info_calculate_forms_block {
        display: block;
    }

    .header_content_info_calculate_result_text {
        margin: 0;
        font-weight: 300;
    }

    .header_content_info_calculate_form_icons {
        margin-left: 0;
    }

    .header_content_advantages_block {
        margin-bottom: 60px;
    }
}

@media (max-width: 480px) {
    .header_content_advantages:nth-child(1) > div {
        border-top: none;
    }

    .header_content_advantages_info_block {
        margin-bottom: 40px;
    }

    .header_content_advantages_number_postfix {
        font-size: 24px;
        line-height: 45px;
    }
}
</style>
